import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ModalController, MenuController } from '@ionic/angular';

import { Plugins } from '@capacitor/core';
import { AngularFireAuth } from "@angular/fire/auth";
const { Geolocation } = Plugins; 
const { Toast } = Plugins;

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalserviceService } from 'src/app/servicio/localservice.service';
// import { Geolocation } from '@ionic-native/geolocation/ngx';

declare var google;

@Component({
  selector: 'app-agregar-direccion',
  templateUrl: './agregar-direccion.page.html',
  styleUrls: ['./agregar-direccion.page.scss'],
})
export class AgregarDireccionPage implements OnInit {
  @Input() direccionS;

  map: any;
  markers = []; 
  
  direccion: any = {} ;
  nuevo : boolean = true;

  coordenadas : any;

  constructor(
    private afAuth: AngularFireAuth,
    public modalController: ModalController,
    private menuCrtl: MenuController,
    private ls: LocalserviceService,
    // private geolocation: Geolocation
    ) {
      this.coordenadas = {};
     }

  ngOnInit() {

  }

  ionViewWillEnter(){

    if(this.direccionS){
      this.nuevo = false;
    }else{
      this.direccionS = {};
    }
    this.direccion = this.direccionS;
    
    this.loadMap();
    console.log('carga mapa')
    
  }


  async obtenerPosicion(){

    let myLatLng;

    // this.geolocation.getCurrentPosition().then((resp) => {
    //   // resp.coords.latitude
    //   // resp.coords.longitude
    //   this.coordenadas = {lat: resp.coords.latitude, lng: resp.coords.longitude};

    //  }).catch((error) => {
    //    console.log('Error getting location', error);
    //  });
    myLatLng= await Geolocation.getCurrentPosition();
    this.coordenadas = {lat: myLatLng['coords'].latitude, lng: myLatLng['coords'].longitude};

  }

  async loadMap(){
      // create a new map by passing HTMLElement
    let mapEle: HTMLElement = document.getElementById('map');
    console.log('mapEle :>> ', mapEle);
    // create LatLng object
    let myLatLng = {lat: -17.783273, lng: -63.182446};
    let zomm = 12;
    if(!this.nuevo){
       myLatLng = {lat: +this.direccion.latitud, lng: +this.direccion.longitud};
       zomm = 16;
       this.crearMapa(myLatLng,zomm,mapEle);
      console.log('!nuevo');
    }else{
      console.log('nuevo');
       if (navigator.geolocation) {
         console.log('0 :>> ', 0);
        var a = navigator.geolocation.getCurrentPosition(
          (position) => {
            var myLatLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('myLatLng :>> ', myLatLng);
            this.crearMapa(myLatLng,zomm,mapEle);
          },
          (errorCallback) => {
            console.log('1 :>> ', 1);
            this.crearMapa(myLatLng,zomm,mapEle);
          }
          );
      } else {
        // Browser doesn't support Geolocation
        console.log('salto el else')
        this.crearMapa(myLatLng,zomm,mapEle);
      }
    }
    console.log(myLatLng,'funcion loadMadp');
  }
  crearMapa(myLatLng,zomm,mapEle){
    // create map
    this.map = new google.maps.Map(mapEle, {
      center: myLatLng,
      zoom: zomm,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      // styles: this.ls.style, 
    });
    let centro = this.map.getCenter();
    this.direccion['lat'] = centro.lat();
    this.direccion['lng'] = centro.lng();
    google.maps.event.addListener(this.map, 'center_changed', () =>{

      setTimeout(()=> {
        let centro = this.map.getCenter();
        this.direccion['lat'] = centro.lat();
        this.direccion['lng'] = centro.lng();
        // console.log(centro.lat());
      }, 500);
    });

}

  anonimoLogin(){
    this.afAuth.auth.signInAnonymously().then( user => {
      console.log(user);
    })
  }

  cerrarModal() {
    this.modalController.dismiss({
      nombre:'',
      detalle:''
    });
  }

  enviarDatos() {

    console.log(this.direccion);
    if(this.direccion.nombre == '' ||this.direccion.nombre == undefined || this.direccion.detalle == '' ||this.direccion.detalle == undefined ){
      this.showToast('Debe completar todos los campos');
    }else{
      this.modalController.dismiss({
        nombre: this.direccion.nombre,
        detalle: this.direccion.detalle,
        longitud: this.direccion['lng'] ,
        latitud: this.direccion['lat'] ,
        check: false
      });
    }
  }

  async showToast( msg ) {
    await Toast.show({
      text: msg
    });
  }
}
