import { Component } from '@angular/core';
import { Plugins, StatusBarStyle } from "@capacitor/core";

import { Platform ,PopoverController, ModalController} from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HostListener } from "@angular/core";
import { LocalserviceService } from './servicio/localservice.service';
import { PopoverNotificacionesComponent } from './componentes/popover-notificaciones/popover-notificaciones.component';
import { Router , NavigationEnd} from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DireccionPage } from './paginas/direccion/direccion.page';

declare let fbq:Function;
declare let gtag:Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  esWeb: boolean = true;
  screenHeight: number;
  screenWidth: number;
  columnaesWeb: number = 3;
  cartItemCount: BehaviorSubject<number>;
  direccion: any = {};

  subscribeDireccionAll: Subscription;

  constructor(
    private platform: Platform,
    private ls: LocalserviceService,
    public popoverController: PopoverController,
    private router: Router,
    public modalController: ModalController,

    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar
  ) {
    ///// ==>>> evento de Facebook Pixel
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
        gtag('config', 'UA-149400253-1',{'page_path' : y.url});

      }
    })

    ///// ==>>> suscripcion para los cambios de direccion de direccion
    this.subscribeDireccionAll = this.ls.getDireccionAll$().subscribe(
      (data) => {
        console.log('data :>> ', data);
        this.direccion = data;
      }, (err) => {
        console.log('err :>> ', err);
      }
    );

    this.initializeApp();
    this.onResize();
    // setTimeout(() => {
    var b = JSON.parse(sessionStorage.getItem('gobox_userApp'));
    if (b) {


      // if (this.ls.estadopopover == false) {
      //   this.agregarDireccion();
      // }

      console.log('existe usuario en sessionStorage')
      this.ls.mostrarNav = true;
    } else {
      this.ls.mostrarNav = false;
    }

    this.cartItemCount = this.ls.getCantidadProductos();





  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.reacomodarweb();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 825) {
      this.esWeb = false;
      this.columnaesWeb = 6;
    } else {
      this.esWeb = true;
      this.columnaesWeb = 4;
    }
  }

  reacomodarweb() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    console.log('Ancho pantall:', this.screenWidth)
    // if (!this.platform.is('desktop')) {

    if (this.screenWidth < 990 && !this.platform.is('desktop')) {
      this.esWeb = false;
      this.columnaesWeb = 6;
    } else {
      this.esWeb = true;
      this.columnaesWeb = 4;
    }
  }
  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     this.statusBar.styleDefault();
  //     this.splashScreen.hide();
  //   });
  // }

  async initializeApp() {
    const { SplashScreen, StatusBar } = Plugins;

    try {
      // await SplashScreen.hide();
      // await StatusBar.setStyle({ style: StatusBarStyle.Light });

      if (this.platform.is('android')) {
        StatusBar.setBackgroundColor({ color: '#CDCDCD' });
      }

      this.platform.ready().then(() => {
        this.reacomodarweb();
        this.platform.backButton.subscribeWithPriority(9999, () => {
          document.addEventListener('backbutton', function (event) {
            event.preventDefault();
            event.stopPropagation();
            console.log('hello');

          }, false);
        });
      });
    } catch (err) {
      console.log("normal browser", err);
    }

  }

  async abrirPopNotificaciones(ev) {
    console.log(' abrir');
    const popover = await this.popoverController.create({
      component: PopoverNotificacionesComponent,
      event: ev,
      mode: 'ios',
      translucent: true,
      // backdropDismiss: false,
      cssClass: "prueba",
    });
    await popover.present();

  }


  async agregarDireccion() {
    console.log('direccion');
    // this.nav.navigateForward('/direccion');
    const modal = await this.modalController.create({
      component: DireccionPage,
      backdropDismiss: false,
      cssClass: "agregarDireccion",

    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log(data, 'direccion modal');
    if (data != undefined && data != null && data != '') {
      // debugger
      sessionStorage.setItem("gobox_direccion", JSON.stringify(data));
      this.ls.estadopopover = true;
      this.direccion.nombre = data.detalle;
      // this.cargarDireccionInicio();
      this.ls.setDireccionAll$(data)
    } else {
      console.log('no guardo direccion')
    }
    // this.direccionMostrar();
  }

  // cargarDireccionInicio() {
  //   this.events.publish('direccion');
  // }
}
