import { Component, OnInit } from '@angular/core';
import { ServicioService } from 'src/app/servicio/servicio.service';
import { LocalserviceService } from 'src/app/servicio/localservice.service';
import { ModalController, ToastController, AlertController, NavController } from '@ionic/angular';
import { AgregarDireccionPage } from '../agregar-direccion/agregar-direccion.page';
import { Router } from '@angular/router';

@Component({
  selector: 'app-direccion',
  templateUrl: './direccion.page.html',
  styleUrls: ['./direccion.page.scss'],
})
export class DireccionPage implements OnInit {
  direcciones: any = [];
  direccion: any = {};
  idCliente: any;
  seleleccion: any = {};
  clienteLogin : boolean;

  constructor(
    private servicio: ServicioService,
    private ls: LocalserviceService,
    public modalController: ModalController,
    private toastController: ToastController,
    public alertController: AlertController,
    private nav: NavController, 
    private router: Router,

  ) { 
    // this.nav.
    console.log('this.router.url', this.router.url);

  }

  ngOnInit() {
  }
  ionViewWillEnter() {

    // if(this.router.url == '/login') {
    //   console.log('asdf');
    //   this.modalController.dismiss();
    // }

    var a = JSON.parse(sessionStorage.getItem('gobox_userApp'));
    if(a && a['idCliente'] != 0) {
      this.clienteLogin = true;
      this.idCliente = a['idCliente'];
      this.obtenerDirecciones(this.idCliente);
    } else { 
      console.log('else :>> ');
      this.clienteLogin = false;
      this.obtenerUbicacionLocalSession()
    }
  }
  obtenerUbicacionLocalSession() {
    var d = JSON.parse(sessionStorage.getItem('gobox_direccion'));
    if(d) {
      this.direcciones.push(d);
    } else {
      // this.AgregarNuevo();
    }
  }
  obtenerDirecciones(id: any) {
    var obj = {};
    obj['idCliente'] = id;
    this.servicio.postGetAllDireccionCliente(obj).subscribe(
      res => {
        let validacion = this.ls.verificarResultado(res);
        if(validacion == '0'){
           this.direcciones = res['msg'];
           console.log(res);
        } else if(validacion == '2'){
          this.toast('No hay datos');
        } else {
          this.toast('Ocurrio un error');
        }
      },
      err => {
        console.log(err)
        this.toast('Ocurrio un error');
      }
    );
  }

  seleccionado(e) {
    console.log(e.detail.value)
    for (let i = 0; i < this.direcciones.length; i++) {
      if(this.direcciones[i]['idDireccion'] == e.detail.value) {
        this.modalController.dismiss(
          this.direcciones[i]
        )
      }
      
    }
  }
  async AgregarNuevo() {
      const modal = await this.modalController.create({
        component: AgregarDireccionPage,
      cssClass: 'agregarDireccion'

      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if(data!= undefined && data['nombre'] && data['detalle']){
        this.direccion = data;
        this.direccion['idCliente'] = this.idCliente;
        this.agregarDireccion(this.direccion)
      } else {
        console.log('modal cerrado');
      }
  }
 


  agregarDireccion(data) {
    if(this.clienteLogin) {
      this.servicio.postInsertDireccion(data).subscribe(
        res => {
          let validacion = this.ls.verificarResultado(res);
          if(validacion == '0'){
            this.obtenerDirecciones(this.idCliente);
            this.toast('Registado con éxito');
          } else if(validacion == '2') {
            this.toast('No fue posible registrar.')
          }else {
            this.toast('Ocurrio un error.');
          }
        },
        err => {
          console.log(err);
          this.toast('Ocurrio un error.');
        }
      );
    } else {
      console.log('false  :>> agregarDireccion ' , data);
      this.direcciones = [];
      this.direcciones.push(data)
      // this.modalController.dismiss(data);
    }
  }

  
  async toast(text: string ) {
    const toast = await this.toastController.create({
      message: text,
      duration: 5000
    });
    toast.present();
  }
  login() {
    this.modalController.dismiss();
    this.nav.navigateForward('login')
  }

}
