import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-tab-web',
  templateUrl: './tab-web.component.html',
  styleUrls: ['./tab-web.component.scss'],
})
export class TabWebComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  esWeb:boolean=true;
  constructor() { }

  ngOnInit() {}
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
 this.screenHeight = window.innerHeight;
 this.screenWidth = window.innerWidth;
 if(this.screenWidth<594){
  this.esWeb=false;
 }else{
   this.esWeb=true;
 }
}
}
