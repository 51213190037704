import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentesModule } from './componentes/componentes.module';
import { HttpClientModule } from '@angular/common/http';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

// import { Camera } from "@ionic-native/camera/ngx";
import { PipesModule } from './pipes/pipes.module';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import { ElasticHeaderDirective } from './directives/elastic-header.directive';
import { OneSignal } from '@ionic-native/onesignal/ngx'; 
import * as firebase from 'firebase';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { PapaParseModule } from 'ngx-papaparse';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PopoverNotificacionesComponent } from './componentes/popover-notificaciones/popover-notificaciones.component';
import { DireccionPage } from './paginas/direccion/direccion.page';
import { DireccionPageModule } from './paginas/direccion/direccion.module';

//
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";


firebase.initializeApp(environment.firebase);
//
// const googleLoginOptions: LoginOpt = {
//   scope: 'profile email'
// };
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("52697987322-dlkqrgq7g1uk38su171fmpog0bk9ebe1.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("581342222706547")
  }
]);

export function provideConfig() {
  return config;
}
@NgModule({ 
  declarations: [AppComponent],
  entryComponents: [PopoverNotificacionesComponent, DireccionPage],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    ComponentesModule,
    PipesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    PapaParseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    DireccionPageModule,
    //
    SocialLoginModule,
    ComponentesModule
  ],
  providers: [
    // StatusBar,
    // Camera,
    // SplashScreen, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PhotoViewer,
    OneSignal,
    Geolocation,
    File,
    SocialSharing,
    InAppBrowser,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
