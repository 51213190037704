import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss'],
})
export class FooterWebComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  esWeb:boolean=true;
  columnaesWeb:number=3;
  constructor() { 
    this.onResize();

  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
 this.screenHeight = window.innerHeight;
 this.screenWidth = window.innerWidth;
 if(this.screenWidth<650){
  this.esWeb=false;
  this.columnaesWeb=6;
 }else{
   this.esWeb=true;
   this.columnaesWeb=3;
 }
}
  ngOnInit() {}

}
