import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-msj-transporte',
  templateUrl: './popover-msj-transporte.component.html',
  styleUrls: ['./popover-msj-transporte.component.scss'],
})
export class PopoverMsjTransporteComponent implements OnInit {
  mensaje:any;
  constructor(private popController: PopoverController,public navParams:NavParams) { 
    
     this.mensaje = this.navParams.get('key1');
    console.log(this.mensaje);
  }

  ngOnInit() {

  }

  cerrarConDatos() {
   this.mensaje= (<HTMLInputElement>document.getElementById('textoTransporte')).value;
    
    this.popController.dismiss(this.mensaje);
  }
  cancelar(){
    this.popController.dismiss(0);

  }
}
