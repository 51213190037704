import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal',
})
export class DecimalPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, args) {
    var a = 0.00;
    var num=parseFloat(value);
    //console.log(num);
    // if(args == "2"){
    //    a = Math.round(value * 100) / 100 ;


    // }else if(args == "1"){
    // //    a = Math.round(value * 10) / 10 ;
    // //    a = Math.round(value).toFixed(1);
    //     var multiplier = Math.pow(10, 1 || 0);
    //     a = Math.round(value * multiplier) / multiplier;
    // }else{
    //     a = 0;
    // }

    switch (args) {
      case "1":
          a = Math.round(value * 100) / 100 ;
        break;
    
      case "2":
          var multiplier = Math.pow(10, 1 || 0);
          a = Math.round(value * multiplier) / multiplier;
        break;

        case "3":
        return  num.toFixed(2);
          
          break;
          case "4":
            a = Math.abs(value);
            break;
    }
    
    return a;
  }
}
