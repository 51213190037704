import { Component, OnInit, Input } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { LocalserviceService } from 'src/app/servicio/localservice.service';
import { PopoverController } from '@ionic/angular';

const { Geolocation } = Plugins;
const { Toast } = Plugins;

declare var google;


@Component({
  selector: 'app-popover-map',
  templateUrl: './popover-map.component.html',
  styleUrls: ['./popover-map.component.scss'],
})
export class PopoverMapComponent implements OnInit {

  datos: any = [];
  productoDisponible:any=0;
  map: any;
  arraySucursales: any;
  direccionCliente: any;
  origen: any;
  destinos: any = [];
  puntos: any = [];
  estadoResumen: boolean;
  // map: any;
  markers = [];

  constructor(
    private ls: LocalserviceService,
    private popoverController: PopoverController
  ) {
    ///// obtener Direccion.
    var d = JSON.parse(sessionStorage.getItem('gobox_direccion'));
    if (d) {
      this.direccionCliente = d;
      this.origen = {};
      this.origen['lat'] = (+this.direccionCliente['latitud']);
      this.origen['lng'] = (+this.direccionCliente['longitud']);
      this.origen['destino'] = this.direccionCliente['detalle'];
      this.origen['url'] = '../../../assets/icon/pin_cliente.svg';
      this.puntos.push(this.origen);
    } else {
      this.direccionCliente = [];
      console.log('no hay datos')
    }

    ///// obtener Sucursales. 
    this.productoDisponible=JSON.parse(sessionStorage.getItem('gobox_carrito_sucursal_disponible'));
    if(this.productoDisponible){
      this.productoDisponible=this.productoDisponible[0]['disponible'];

    }else{
      this.productoDisponible=0;
    }
    var a = JSON.parse(sessionStorage.getItem('gobox_carrito_sucursal'));
    if(this.productoDisponible==1){
      var a = JSON.parse(sessionStorage.getItem('gobox_carrito_sucursal_disponible'));

    }
    if (a) {
      this.arraySucursales = a;


    } else {
      this.arraySucursales = [];
      console.log('no hay datos')
    }
  }
  ionViewWillEnter() {
    this.estadoResumen = this.ls.estadoResumen = true;
    console.log(this.estadoResumen);
    console.log(this.ls.estadoResumen);

  }
  async calcularCercano(origin1, destinationA, index) {

    var service = await new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1],
        destinations: [destinationA],
        travelMode: 'DRIVING',
        avoidHighways: false,
        avoidTolls: false,
      }, response => {
        var origins = response.originAddresses;
        var destinations = response.destinationAddresses;
        for (var i = 0; i < origins.length; i++) {
          var results = response.rows[i].elements;
          for (var j = 0; j < results.length; j++) {
            console.log(results[j].distance.text, 'distancia texto');
            this.arraySucursales[index]['distancia'] = results[j].distance.text;
            this.arraySucursales[index]['valor'] = results[j].distance.value;
            this.arraySucursales[index]['tiempo'] = results[j].duration.text;
            this.arraySucursales[index]['origen'] = origins[i];
            this.arraySucursales[index]['destino'] = destinations[j];
            this.arraySucursales[index]['url'] = '../../../assets/icon/pin_box.svg';

          }
        }
        this.ordenar();

      });


  }

  ordenar() {
    var cant =0;
    for (let i = 0; i < this.arraySucursales.length; i++) {
      if( this.arraySucursales[i].valor) {
        cant = cant +1;
      }
    }


    if ( cant == this.arraySucursales.length ) {
      console.log(this.arraySucursales, 'antes de ordenar.')

      this.arraySucursales.sort(function (a, b) {
        if (a.valor > b.valor) {
          return 1;
        }
        if (a.valor < b.valor) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      console.log(this.arraySucursales, 'despues de ordenar');
      this.cargarPuntos();
    } else {
      console.log('le falta valor de distancia a una sucursal.')
    }


  }

  ngOnInit() {
    for (let i = 0; i < this.arraySucursales.length; i++) {
      var origin1 = new google.maps.LatLng(this.origen['lat'], this.origen['lng']);
      var destinationA = new google.maps.LatLng((+this.arraySucursales[i]['latitud']), (+this.arraySucursales[i]['longitud']));
      this.calcularCercano(origin1, destinationA, i);

    }
    // this.cargarPuntos();
    // this.loadMap();


  }

  calcularDistanciaRuta() {
    var origen;
    var destino;
    var totalMetros: number = 0;
    console.log('calcularDistanciaRuta PUNTOS', this.puntos)
    for (let i = 0; i < this.puntos.length; i++) {

      if (i + 1 == this.puntos.length) {

        console.log('no hay mas puntos.')

      } else {
        origen = this.puntos[i];
        destino = this.puntos[i + 1];
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
          origins: [origen],
          destinations: [destino],
          travelMode: 'DRIVING',
          avoidHighways: false,
          avoidTolls: false,
        }, response => {
          var origins = response.originAddresses;
          var destinations = response.destinationAddresses;
          for (var i = 0; i < origins.length; i++) {
            var results = response.rows[i].elements;
            for (var j = 0; j < results.length; j++) {
              totalMetros = totalMetros + results[j].distance.value;
            }
          }
          console.log('totalMetros', totalMetros);
          sessionStorage.setItem("gobox_calculo_distancia", JSON.stringify(totalMetros));
        });
      }
    }
  }

  cargarPuntos() {
    for (let i = 0; i < this.arraySucursales.length; i++) {
      var obj = {};
      obj['lat'] = (+this.arraySucursales[i]['latitud']);
      obj['lng'] = (+this.arraySucursales[i]['longitud']);
      obj['url'] = '../../../assets/icon/pin_box.svg';
      obj['destino'] = this.arraySucursales[i]['direccionsucursal'];
      console.log(obj, '////////////////////////------------------------->')
      this.puntos.push(obj);
    }
    // console.log('this.puntos :', this.puntos);
    this.calcularDistanciaRuta();

    this.loadMap();

  }


  async loadMap() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 12,
      center: { lat: -17.787347, lng: -63.196147 },
      mapTypeId: 'terrain',
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      styles: this.ls.style,
    });

    // console.log('this.puntos :', this.puntos);




    var flightPath = new google.maps.Polyline({
      path: this.puntos,
      geodesic: true,
      strokeColor: '#ffd31a',
      strokeOpacity: 1.0,
      strokeWeight: 3.5
    });

    flightPath.setMap(this.map);
    for (let i = 0; i < this.puntos.length; i++) {
      this.addMarker(this.puntos[i], this.puntos[i]['destino'], this.puntos[i]['url'])
    }


  }
  addMarker(puntos, msj, ic) {
    let marker = new google.maps.Marker({
      icon: new google.maps.MarkerImage(ic, null, null, null, new google.maps.Size(40,40)),
      animation: google.maps.Animation.DROP,
      map: this.map,
      position: puntos,
    });
    let content = `<h6 >${msj}</h6>`
    let infoWindow = new google.maps.InfoWindow({
      content: content
    });
    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(this.map, marker);
    });
    this.markers.push(marker);
    // console.log('this.markers :', this.markers);
  }



  clickAceptar() {
    this.popoverController.dismiss({
      retorno: true
    });
  }
  clickCancelar() {
    this.popoverController.dismiss({
      retorno: false
    });
  }


}
