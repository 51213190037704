import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalserviceService } from 'src/app/servicio/localservice.service';
import { NavController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})


export class ClienteAutenticadoGuard implements CanActivate {

  rutasInvalidas: any[] = [
    'perfil',
    'compras-recientes',
    'resumen-compra',
    'historial-cosito',
    'presupuesto',
    'presupuesto-lista',
    'historial',
    'estado-pedido',
    'buscar-cosito', 
    'notificaciones', 
    'detalle-compra',
    'estado-pedido/:idPedido'
  ]

  constructor(
    private ls: LocalserviceService,
    private nav: NavController,
    private alertController: AlertController
  ) {

  }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('next.routeConfig.path :>> ', next.routeConfig.path);

    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    console.log('user :>> ', user);
    if (user && user['idCliente'] != 0) {
      console.log('user :>> ', user);
      return true;
    } else if( user && user['idCliente'] == 0) {
      
      if(this.verificarRuta(next.routeConfig.path) == true) {
          const alert = await this.alertController.create({
            header: 'Inicio de sesión necesario!',
            message: 'Para obtener esta información, debes iniciar sesión',
            buttons: [
              {
                text: 'Continuar Navegando',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel: blah');
                  this.nav.back();
                }
              }, {
                text: 'Iniciar Sesión',
                handler: () => {
                  console.log('Confirm Okay');
                  sessionStorage.setItem("gobox_ruta_retorno", state.url );
                  this.nav.navigateRoot('login')
                }
              }
            ]
          });
        
          await alert.present();
        // this.alertaLogin();

      } else {
        this.ls.mostrarNav = true;
        return true;
      }
    } else {
      console.log('cliente invitado');
      let userInvitado = {};
      userInvitado['idCliente'] = 0;
      userInvitado['token'] = 0;
      userInvitado['nombre'] = 'Invitado';
      userInvitado['foto'] = '../assets/img/perfil/user.png';
      sessionStorage.setItem("gobox_userApp", JSON.stringify(userInvitado));
      if(this.verificarRuta(next.routeConfig.path) == true) {

        const alert = await this.alertController.create({
          header: 'Inicio de sesión necesario!',
          message: 'Para obtener esta información, debes iniciar sesión',
          buttons: [
            {
              text: 'Continuar Navegando',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Confirm Cancel: blah');
                this.nav.back();
              }
            }, {
              text: 'Iniciar Sesión',
              handler: () => {
                console.log('Confirm Okay');
                sessionStorage.setItem("gobox_ruta_retorno", state.url );
                this.nav.navigateRoot('login')
              }
            }
          ]
        });
      
        // await alert.present();

        // this.alertaLogin();
        // sessionStorage.setItem("gobox_ruta_retorno", state.url );
        // this.nav.navigateRoot('login')
      } else {
        this.ls.mostrarNav = true;

        return true;
        
      }
    }
    return true;

  }

  verificarRuta(url) {
    let i = this.rutasInvalidas.indexOf(url)
    console.log('i :>> ', i);
    if (i > -1) {
      // alamacenar ruta en local para retornar luego de hacer login. => state.url
      return true;
    } else {
      return false;
    }
  }
  async alertaLogin() {
    const alert = await this.alertController.create({
      header: 'Inicio de sesión necesario!',
      // subHeader: 'Subtitle',
      message: 'Para obtener esta información, debes iniciar sesión',
      buttons: ['Aceptar']
    });
  
    await alert.present();
  }


}
