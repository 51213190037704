import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-msj-proveedor',
  templateUrl: './popover-msj-proveedor.component.html',
  styleUrls: ['./popover-msj-proveedor.component.scss'],
})
export class PopoverMsjProveedorComponent implements OnInit {
  mensaje: any;

  constructor(private popController: PopoverController,public navParams:NavParams) { 
    this.mensaje = this.navParams.get('key1');

  }

  ngOnInit() {}

  cerrarConDatos() {
    this.mensaje= (<HTMLInputElement>document.getElementById('textoTransporte')).value;
     
     this.popController.dismiss(this.mensaje);
   }
   cancelar(){
     this.popController.dismiss(0);
 
   }

}
