import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';

import { FechasPipe } from './fechas/fechas';
import { DecimalPipe } from './decimal/decimal';


@NgModule({
  declarations: [FiltroPipe  ,  FechasPipe,DecimalPipe],
  exports: [FiltroPipe, FechasPipe, DecimalPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
