// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyDjFqzUVEaF82J4-W0xUp6LLVut3Qa_7xo",
    apiKey: "AIzaSyBOeQnqil8oVfgQODwdd9gatNj-Snuxw0I",
    authDomain: "gobox-30df5.firebaseapp.com",
    databaseURL: "https://gobox-30df5.firebaseio.com",
    projectId: "gobox-30df5",
    storageBucket: "gobox-30df5.appspot.com",
    messagingSenderId: "52697987322",
    appId: "1:52697987322:web:c7d8fb707514a4f69c3143",
    measurementId: "G-J9S3D1TMHB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
