import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { DireccionPage } from './direccion.page';
import { AgregarDireccionPage } from '../agregar-direccion/agregar-direccion.page';
import { AgregarDireccionPageModule } from '../agregar-direccion/agregar-direccion.module';

// const routes: Routes = [
//   {
//     path: '',
//     component: DireccionPage
//   }
// ];

@NgModule({
  entryComponents:[
    AgregarDireccionPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AgregarDireccionPageModule,
    // RouterModule.forChild(routes)
  ],
  declarations: [DireccionPage]
})
export class DireccionPageModule {}
