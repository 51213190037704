import { Injectable } from '@angular/core';
import { Menu } from '../interfaces/interfaces';
import { HttpClient } from '@angular/common/http'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ServicioService {

  // API_Uri = 'https://demo.goboxapp.com/API_REST';


  //desarrollo
  // API_Uri = 'https://demo.goboxapp.com/Desarrollo/Cliente/API_REST';
  // API_Uri = 'http://localhost:8888/proyectosGIT/GOBOX/gobox-userapp/API_REST';

  // db_firebase = 'pedido-dev';

  //produccion
  API_Uri = 'https://web.goboxapp.com/API_REST';
  API_Uri2 = 'https://cms.goboxapp.com/API_REST';
  db_firebase = 'pedido';


  private pedidoCollection: AngularFirestoreCollection<any>;
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,

  ) {

    this.pedidoCollection = this.afs.collection<any>(this.db_firebase);

  }

  addFirebasePedido(obj: any) {
    return this.pedidoCollection.add(obj);
  }
  getOnePedidoFirebase(idPedido) {
    var peCollection = this.afs.collection<any>(this.db_firebase, (ref) => ref.where('idPedido', '==', +idPedido));
    var ped = peCollection.snapshotChanges().pipe(map(
      actions => {
        var act = actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
        return act;
      },
      err => {
        console.log('err :', err);
      }
    ));
    return ped;
  }

  deletePedidoFirebase(id: any) {
    return this.pedidoCollection.doc(id).delete();
  }


  crearOrdenPago(obj: any) {
    // return this.http.post(`http://devkqtest.eastus2.cloudapp.azure.com/KXPaymentTR/HostedService.svc/CreateOrder`, obj);
    return this.http.post(`https://services.kxperience.com/KXPaymentTR/HostedService.svc/CreateOrder`, obj);
    
  }

  // CLI11GB - OBTENER INFORMACIÓN DE PEDIDO
  getInfoPedido(idPedido: any) {
    return this.http.get(`${this.API_Uri}/getInfoPedido/${idPedido}`);
  }

  //CLI13GB - POST - OBTENER DETALLE DE PRODUCTO
  postObtenerDetalleProducto(obj: any) {
    return this.http.post(`${this.API_Uri}/postObtenerDetalleProducto`, obj);
  }
  //GB18CON - POST - ACTUALIZAR DATOS CLIENTES
  postActualizarDatosCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postActualizarDatosCliente`, obj);
  }

  getProductoProveedorXnombre(obj: any) {
    return this.http.post(`${this.API_Uri}/getProductoProveedorXnombre`, obj);
  }
  
  getProductoRandom(random: any, posicion: any, lat: any, lng: any) {
    return this.http.get(`${this.API_Uri}/getProductoRandom/${random}/${posicion}/${lat}/${lng}`);
  }

  getMenu() {
    return this.http.get<Menu[]>('/assets/data/menu.json');
  }
  getProducto() {
    return this.http.get<any[]>('/assets/data/productos.json');
  }

  postLogin(obj: any) {
    return this.http.post(`${this.API_Uri}/postLogin`, obj);
  }
  postLoginTelefono(obj: any) {
    return this.http.post(`${this.API_Uri}/postLoginTelefono`, obj);
  }
  loginRedes(obj: any) {
    return this.http.post(`${this.API_Uri}/loginRedes`, obj);
  }
  correoexistente(correo: any) {
    return this.http.get(`${this.API_Uri}/existeCorreoCliente/${correo}`);
  }
  olvidepass(obj: any) {
    return this.http.post(`${this.API_Uri}/olvidepass`, obj);
  }
  modificarCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/modificarCliente`, obj);
  }
  getClienteone(id: any) {
    return this.http.get(`${this.API_Uri}/getCliente/${id}`);
  }
  postClienteNuevo(obj: any) {
    return this.http.post(`${this.API_Uri}/nuevoCliente`, obj);
  }
  postInsertDireccion(obj: any) {
    return this.http.post(`${this.API_Uri}/nuevaDireccion`, obj);
  }
  postUpdatePasswordCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/postUpdatePasswordCliente`, obj);
  }
  postGetAllDireccionCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/direccionCliente`, obj);
  }
  postUpdateDireccionCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/modificarDireccion`, obj);
  }
  postDeleteDireccionCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/EliminarDireccion`, obj);
  }
  getCositoProveedor(obj: any) {
    return this.http.get(`${this.API_Uri}/getCositoProveedor/${obj}`,);
  }
  getCositodetalleProveedor(obj: any, lat: any, lon: any) {
    return this.http.get(`${this.API_Uri}/getCositoCliente/${obj}/${lat}/${lon}`,);
  }
  getPedidosCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/getpedido`, obj);
  }
  getPedidosone(obj: any) {
    return this.http.post(`${this.API_Uri}/getpedidoone`, obj);
  }
  getPedidosClientefecha(obj: any) {
    return this.http.post(`${this.API_Uri}/getpedidofechas`, obj);
  }
  getpedidofechassinlimite(obj: any) {
    return this.http.post(`${this.API_Uri}/getpedidofechassinlimite`, obj);
  }
  insertartransacciongobox(idcliente: number, monto: any, idpedido: number) {
    return this.http.get(`${this.API_Uri}/insertartransacciongobox/${idcliente}/${monto}/${idpedido}`);
  }
  cargarCalificar(obj: any) {
    return this.http.post(`${this.API_Uri}/cargarCalificar`, obj);
  }
  insertarcalificacion(proveedores: any) {
    return this.http.post(`${this.API_Uri}/insertarcalificacion`, proveedores);
  }
  insertartransaccioncuentacliente(idcuenta: number, monto: any, idtipo: number, idpedido: number) {
    return this.http.get(`${this.API_Uri}/insertartransaccioncuentacliente/${idcuenta}/${monto}/${idtipo}/${idpedido}`);
  }
  getNoticias() {
    return this.http.get(`${this.API_Uri}/getnoticias`)
  }
  productomasvendido() {
    return this.http.get(`${this.API_Uri}/productomasvendido`)
  }
  getProductosMes(id: number, token: string) {
    return this.http.get(`${this.API_Uri}/getProductoMes/0/0/${id}/${token}`)
  }
  getProductosPromo(id: number, token: string, lat: any, long: any, lim: any, pos: any) {
    return this.http.get(`${this.API_Uri}/getProductoPromo/${id}/${token}/${lat}/${long}/${lim}/${pos}`);
  }
  getCategoriaCliente() {
    return this.http.get(`${this.API_Uri}/getCategoriaCliente`);
  }
  getsubCategoriaCliente() {
    return this.http.get(`${this.API_Uri}/getsubCategoriaCliente`);
  }
  setInsertarCositoProveedor(obj: any) {
    return this.http.post(`${this.API_Uri}/insertarCositoProveedor`, obj);
  }
  setInsertarSolicitudPresupuesto(obj: any) {
    return this.http.post(`${this.API_Uri}/setInsertarSolicitudPresupuesto`, obj);
  }
  setUpdateFotoPerfilCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/editarimagen`, obj);
  }
  getPropuestaPedido(idPedido: any) {
    return this.http.get(`${this.API_Uri}/getPropuestaPedido/${idPedido}`);
  }
  getProductosTipo(idTipo: number, latitud, longitud, idToken: number, token: string, limite, posicion) {
    return this.http.get(`${this.API_Uri}/getProductoSubcategoria/${idTipo}/${latitud}/${longitud}/${idToken}/${token}/${posicion}/${limite}`);
  }
  getDetalleProducto(idProd: any, latitud, longitud, idToken: number, token: string, idSucursal: number) {
    return this.http.get(`${this.API_Uri}/getDetalleProducto/${idProd}/${latitud}/${longitud}/${idToken}/${token}/${idSucursal}`);
  }
  getBuscarProducto(texto, latitud, longitud, limite, posicion) {
    return this.http.get(`${this.API_Uri}/buscarProductocliente/${texto}/${latitud}/${longitud}/${limite}/${posicion}`);
  }

  postObtenerProveedores(obj: any) {
    return this.http.post(`${this.API_Uri}/getproveedores`, obj);
  }

  postObtenerProveedorXid(obj: any) {
    return this.http.post(`${this.API_Uri}/postObtenerProveedorXid`, obj);
  }
  
  pedidotarjetasincancelar(obj: any) {
    return this.http.post(`${this.API_Uri}/pedidotarjetasincancelar`, obj);
  }
  getProductoProveedor(obj: any) {
    return this.http.post(`${this.API_Uri}/getProductoProveedor`, obj);
  }
  getProveedorCategoria(obj: any) {
    return this.http.post(`${this.API_Uri}/getProveedorCategoria`, obj);
  }
  getsucursalone(idproveedor: any) {
    return this.http.get(`${this.API_Uri}/getsucursalone/${idproveedor}`);
  }
  getCalificacionConductorone(idPedido: any) {
    return this.http.get(`${this.API_Uri}/getCalificacionConductorone/${idPedido}`);
  }
  insertarpedido(obj: any) {
    return this.http.post(`${this.API_Uri}/insertarpedido`, obj);
  }
  insertarpedidoWeb(obj: any) {
    return this.http.post(`${this.API_Uri}/insertarpedidoWeb`, obj);
  }
  async getValidarClienteTelefono(obj: any) {
    const data = await new Promise(resolve => {
      this.http.get(`${this.API_Uri}/getValidarClienteTelefono/${obj}`).subscribe(
        res => {
          resolve(res);
        }
      );
    });

    return data;
    // return this.http.get(`${this.API_Uri}/getValidarNumeroTelefono/${obj}`);
  }
  insertarpedidoDisponible(obj: any) {
    return this.http.post(`${this.API_Uri}/insertarpedidoDisponible`, obj);
  }
  getConductorpedido(obj: number) {
    return this.http.get(`${this.API_Uri}/getConductorVehiculo/${obj}`,);

  }
  filtrarSubcategorias(obj) {
    return this.http.post(`${this.API_Uri}/filtrarSubcategorias`, obj);
  }
  buscarFiltroPropiedades(obj) {
    return this.http.post(`${this.API_Uri}/filtrarPropiedades`, obj);
  }

  getNorificacionesCliente(idCliente: any, token: any, limite: any, posicion: any) {
    return this.http.get(`${this.API_Uri}/getnotificaciones/${idCliente}/${token}/${limite}/${posicion}`);
  }
  getLogPedido(obj: any) {
    return this.http.post(`${this.API_Uri}/getlogpedido`, obj);

  }
  cancelarPedido(obj: any) {
    return this.http.post(`${this.API_Uri}/cancelarpedido`, obj);

  }
  getSaldoCuentaCliente(obj: any) {
    return this.http.post(`${this.API_Uri}/getcuentacliente`, obj);
  }
  getListaTransaccionClienteFecha(obj: any) {
    return this.http.post(`${this.API_Uri}/getfechatransaccioncuentacliente`, obj);
  }

  getPedidoPendienteMonitoreo(obj: any) {
    return this.http.post(`${this.API_Uri}/getpedidoConductorCamino`, obj);
  }

  setTokenPush(obj: any) {
    return this.http.post(`${this.API_Uri}/setTokenPush`, obj);
  }
  obtenerPrecioTransporte(obj: any) {
    return this.http.post(`${this.API_Uri}/getCostoTransporte`, obj);

  }
  postcorreostock(obj: any) {
    return this.http.post(`${this.API_Uri}/postcorreostock`, obj);
  }
  getcorreoContacto(obj: any) {
    return this.http.post(`${this.API_Uri}/getcorreoContacto`, obj);
  }

  getconfiguracionone(obj: any) {
    return this.http.get(`${this.API_Uri}/getconfiguracionone/${obj}`,);
  }
  gettipoVehiculo() {
    return this.http.get(`${this.API_Uri}/gettipoVehiculo`,);
  }
  gettransaccioncuentaclienteone(obj: any) {
    return this.http.post(`${this.API_Uri}/gettransaccioncuentaclienteone`, obj);
  }
  cancelarpedido(idPedido: any) {
    return this.http.post(`${this.API_Uri}/cancelarpedido`, idPedido);
  }
  verificarPedidoparaPagar(idPedido: any) {
    return this.http.get(`${this.API_Uri}/verificarPedidoparaPagar/${idPedido}`,);
  }
  cancelarPropuesta(idPedido: any) {
    return this.http.get(`${this.API_Uri}/cancelarPropuesta/${idPedido}`,);
  }
  aceptarPropuesta(idPedido: any, precioTransporte, total) {
    return this.http.get(`${this.API_Uri}/aceptarPropuesta/${idPedido}/${precioTransporte}/${total}`,);
  }
  cancelarpedido_disponibilidad(idPedido: any) {
    return this.http.post(`${this.API_Uri}/cancelarpedido_disponibilidad`, idPedido);
  }
  aceptarPedidoDisponible(obj: any) {
    return this.http.post(`${this.API_Uri}/aceptarPedidoDisponible`, obj);
  }
  getMaterialesSubcategoria() {
    return this.http.get(`${this.API_Uri}/getMaterialesSubcategoria/1`,);
  }
  getCalculadoraMateriales(idmaterial: any, lat: any, lng: any) {
    return this.http.get(`${this.API_Uri}/getCalculadoraMateriales/${idmaterial}/${lat}/${lng}`);
  }
  postEnviarCorreoMateriales(obj: any) {
    return this.http.post(`${this.API_Uri}/postEnviarCorreoMateriales`, obj);
  }
  postValidarCupon(obj: any) {
    return this.http.post(`${this.API_Uri}/postValidarCupon`, obj);
  }
  postValidarReferido(obj: any) {
    return this.http.post(`${this.API_Uri}/postValidarReferido`, obj);
  }


  getInfoContrato(obj: any) {
    return this.http.get(`${this.API_Uri}/getInfoContrato/${obj}`);
  }
  
  postContrato(obj: any) {
    return this.http.post(`${this.API_Uri}/postContrato`, obj);
  }

  getCatalogo(obj: any) {
    return this.http.get(`${this.API_Uri2}/getCatalogo/${obj}`);
  }

}
