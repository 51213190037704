import { Injectable } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from "@ionic/angular";
import { BehaviorSubject, Subject, interval, Subscription } from 'rxjs';
// import { DireccionPage } from '../paginas/direccion/direccion.page';
import { DireccionPage } from '../paginas/direccion/direccion.page';

@Injectable({
  providedIn: 'root'
})
export class LocalserviceService {
  //mostrar navBas components.ts
  mostrarNav: boolean = false;

  objFiltro: any;
  idFiltro: any;
  LScatProd: any;
  LSsubCatProd: any;

  // cantidadProductos = 0;
  private cantidadProductos = new BehaviorSubject(0);

  estadoResumen: boolean = false;
  estadopopover: boolean = false;

  style: any = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#263c3f"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6b9a76"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#38414e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#212a37"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9ca5b3"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#1f2835"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#f3d19c"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2f3948"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#515c6d"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    }
  ];
  proveedor: any;

  direccion: any;
  direccion$ = new Subject<any>();
  modal: any; 
  obserEstado = false;
  private observable: any;
  private _subscription: Subscription;

  constructor(public alertController: AlertController,
    private nav: NavController,
    public toastController: ToastController,
    public modalController: ModalController,
    // private ls: LocalserviceService
  ) {

  }





  setDatosFiltro(obj: any) {
    this.objFiltro = obj;
  }
  getDatosFiltro() {
    if (this.objFiltro) {
      return this.objFiltro;
    } else {
      return 'no hat datos'
    }
  }
  setIdFiltro(id: any) {
    this.idFiltro = id;
  }
  getIdFiltro() {
    return this.idFiltro;
  }

  setProveedorSeleccionado(obj) {
    this.proveedor = obj;
  }
  getProveedorSeleccionado() {
    return this.proveedor;
  }


  verificarResultado(res): string {

    if (res.errorCode == 4) { // token de sesion no valido
      this.alertaToken();
    } else if (res.errorCode == 2) { // no hay datos
      return '2';
    } else if (res.errorCode == 3) { //hay error
      this.mensajeToast('error al ejecutar el servicio');
      return 'error';
    } else if (res.errorCode == 0) {
      return '0';
    } else if (res.errorCode == 5) {
      return '5';
    } else {
      return 'error';
    }

  }


  getallcliente() {
    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    return user;
  }
  getIdCliente() {
    // debugger;
    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    if (user) {
      return user['idCliente'];
    } else {
      return 0;
    }
  }

  getTokenCliente() {
    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    if (user) {
      return user['token'];
    } else {
      return 0;
    }
  }

  getTokenPushCliente() {
    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    if (user) {
      return user['tokenpush'];
    } else {
      return user['tokenpush'] = 0;
    }
  }
  setTokenPush(token) {
    let userApp = JSON.parse(sessionStorage.getItem('gobox_userApp'));
    userApp['tokenpush'] = token;
    sessionStorage.setItem('gobox_userApp', JSON.stringify(userApp));
  }
  setTokenReferido(token) {
    let userApp = JSON.parse(sessionStorage.getItem('gobox_userApp'));
    userApp['tokenReferido'] = token;
    sessionStorage.setItem('gobox_userApp', JSON.stringify(userApp));
  }
  getTokenReferido() {
    let user = JSON.parse(sessionStorage.getItem("gobox_userApp"));
    if (user) {
      if(user['tokenReferido']){
        return user['tokenReferido'];
      }else{
        return user['tokenReferido'] = 0;
      }
    } else {
      return user['tokenReferido'] = 0;
    }
  }
  getdireccionlatitud() {
    let user = JSON.parse(sessionStorage.getItem("gobox_direccion"));
    return user['latitud'];
  }
  getdireccionlongitud() {
    let user = JSON.parse(sessionStorage.getItem("gobox_direccion"));
    return user['longitud'];
  }
  checkCantidadProductos() {
    var a = JSON.parse(sessionStorage.getItem('gobox_carrito_sucursal'));
    if (a) {
      let total = a.length;
      this.cantidadProductos.next(this.cantidadProductos.value + total);

    } else {
      this.cantidadProductos.next(this.cantidadProductos.value - this.cantidadProductos.value);

    }
  }

  getCantidadProductos() {
    return this.cantidadProductos;
  }
  getCantidadProductosWeb() {
    var a = JSON.parse(sessionStorage.getItem('gobox_carrito_sucursal'));
    if (a) {
      let total = 0;
      for (let index = 0; index < a.length; index++) {
        total += a[index]['producto'].length;

      }
      this.cantidadProductos.next(total);
      console.log(total);

    } else {
      this.cantidadProductos.next(this.cantidadProductos.value - this.cantidadProductos.value);

    }

  }
  addProductos(num) {
    // this.cantidadProductos = this.cantidadProductos + num;
    this.cantidadProductos.next(this.cantidadProductos.value + num);
  }
  rmProductos(num) {
    // this.cantidadProductos = this.cantidadProductos - num;
    this.cantidadProductos.next(this.cantidadProductos.value - num);

  }
  limpiarProductos() {
    // this.cantidadProductos.value = 0;
    this.cantidadProductos.next(this.cantidadProductos.value - this.cantidadProductos.value);

  }


  getDireccionAll() {
    if(this.direccion == undefined){
      this.direccion = { 
        check: false,
        detalle: "Dirección Pre-Determinada, Santa Cruz",
        idDireccion: "-1",
        latitud: -17.783315,
        longitud: -63.182132,
        nombre: "Centro de Santa Cruz.",
      }
      sessionStorage.setItem("gobox_direccion", JSON.stringify(this.direccion));
    }
    return this.direccion;
  }
  setDireccionAll(data: any) {
    console.log('data :>> ', data);
    this.direccion = data;
  }

  setDireccionAll$(data: any) {
    this.direccion = data;
    this.direccion$.next(data);
  }
  getDireccionAll$(): Subject<any> {
    return this.direccion$;
  }


  /// componentes
  async alertaToken() {
    const alert = await this.alertController.create({
      header: 'Tu Sesión he expirado',
      backdropDismiss: false,
      message: 'Seguramente haz abierto tu cuenta en otro dispositivo. Antes de continuar ingresa los datos de tu cuenta',
      buttons: [
        {
          text: 'Ingresar a mi cuenta',
          handler: () => {
            console.log('Confirm Okay');
            this.nav.navigateRoot('/login');
          }
        }
      ]
    });
    await alert.present();
  }
  async mensajeToast(mensaje) {
    const toast = await this.toastController.create({
      message: mensaje,
      duration: 4000
    });
    await toast.present();
  }


}
