import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DireccionGuard } from './guards/direccion.guard';
import { ClienteAutenticadoGuard } from './guards/cliente-autenticado.guard';
import { LoginGuard } from './guards/login.guard';
const routes: Routes = [
  { path: '', redirectTo: 'menu-web', pathMatch: 'full' },
  { path: 'menu-web', loadChildren: './paginas/menu-web/menu-web.module#MenuWebPageModule' },
  // { path: 'inicio', canActivate: [DireccionGuard, ClienteAutenticadoGuard] , loadChildren: './paginas/inicio/inicio.module#InicioPageModule'},

  { path: 'login', canActivate: [LoginGuard ], loadChildren: './paginas/login/login.module#LoginPageModule' },
  // { path: 'verificar', loadChildren: './paginas/verificar/verificar.module#VerificarPageModule' },
  { path: 'recuperar-clave', loadChildren: './paginas/recuperar-clave/recuperar-clave.module#RecuperarClavePageModule' },
  { path: 'registrarse', loadChildren: './paginas/registrarse/registrarse.module#RegistrarsePageModule' },
  // { path: 'inicio', canActivate: [DireccionGuard, ClienteAutenticadoGuard] , loadChildren: './paginas/inicio/inicio.module#InicioPageModule'},
  // { path: 'inicio/:nuevo', loadChildren: './paginas/inicio/inicio.module#InicioPageModule' },
  // { path: 'perfil', loadChildren: './paginas/perfil/perfil.module#PerfilPageModule' },
  // { path: 'producto/:idproducto', loadChildren: './paginas/producto/producto.module#ProductoPageModule' },
  // { path: 'producto/:idproducto/:idSucursal', loadChildren: './paginas/producto/producto.module#ProductoPageModule' },
  // { path: 'historial', loadChildren: './paginas/historial/historial.module#HistorialPageModule' },
  // { path: 'compras-recientes', loadChildren: './paginas/compras-recientes/compras-recientes.module#ComprasRecientesPageModule' },
  // { path: 'calculadora', loadChildren: './paginas/calculadora/calculadora.module#CalculadoraPageModule' },
  // { path: 'monitoreo-pedido', loadChildren: './paginas/monitoreo-pedido/monitoreo-pedido.module#MonitoreoPedidoPageModule' },
  // { path: 'carrito', loadChildren: './paginas/carrito/carrito.module#CarritoPageModule' },
  // { path: 'carrito/:estadoCompra', loadChildren: './paginas/carrito/carrito.module#CarritoPageModule' },

  // { path: 'paginicio', loadChildren: './paginas/paginicio/paginicio.module#PaginicioPageModule' },

  // { path: 'estado-pedido/:idPedido', loadChildren: './paginas/estado-pedido/estado-pedido.module#EstadoPedidoPageModule' },
  // { path: 'notificaciones', loadChildren: './paginas/notificaciones/notificaciones.module#NotificacionesPageModule' },
  // { path: 'buscar-cosito', loadChildren: './paginas/buscar-cosito/buscar-cosito.module#BuscarCositoPageModule' },
  { path: 'comercio', loadChildren: './paginas/comercio/comercio.module#ComercioPageModule' },
  // { path: 'resumen-compra', loadChildren: './paginas/resumen-compra/resumen-compra.module#ResumenCompraPageModule' },
  // { path: 'respuesta-cosito/:idpropuesta', loadChildren: './paginas/respuesta-cosito/respuesta-cosito.module#RespuestaCositoPageModule' },
  // { path: 'respuesta-cosito/historial/:idhistorial', loadChildren: './paginas/respuesta-cosito/respuesta-cosito.module#RespuestaCositoPageModule' },
  
  { path: 'historial-lista', loadChildren: './paginas/historial-lista/historial-lista.module#HistorialListaPageModule' },
  { path: 'detalle-transaccion/:idTransaccionCliente', loadChildren: './paginas/detalle-transaccion/detalle-transaccion.module#DetalleTransaccionPageModule' },
  // { path: 'tabs', loadChildren: './paginas/tabs/tabs.module#TabsPageModule' },
  { path: 'bienvenida', loadChildren: './paginas/bienvenida/bienvenida.module#BienvenidaPageModule' },
 
  // { path: 'ofertas-lista', loadChildren: './paginas/ofertas-lista/ofertas-lista.module#OfertasListaPageModule' },
  // { path: 'historial-cosito', loadChildren: './paginas/historial-cosito/historial-cosito.module#HistorialCositoPageModule' },
  // { path: 'calificar', loadChildren: './paginas/calificar/calificar.module#CalificarPageModule' },
  // { path: 'calificar/:idPedido', loadChildren: './paginas/calificar/calificar.module#CalificarPageModule' },
  // { path: 'menu', loadChildren: './paginas/menu/menu.module#MenuPageModule' },
  // { path: 'buscador', loadChildren: './paginas/buscador/buscador.module#BuscadorPageModule' },
  // { path: 'buscador',  canActivate: [DireccionGuard] ,loadChildren: './paginas/buscador/buscador.module#BuscadorPageModule' },
  // { path: 'contactenos', loadChildren: './paginas/contactenos/contactenos.module#ContactenosPageModule' },
  
  // { path: 'terminos', loadChildren: './paginas/terminos/terminos.module#TerminosPageModule' },
  { path: 'actualizar-clave', loadChildren: './paginas/actualizar-clave/actualizar-clave.module#ActualizarClavePageModule' },
  // { path: 'menu-filtro', loadChildren: './paginas/menu-filtro/menu-filtro.module#MenuFiltroPageModule' },

  // { path: 'listaproveedor', loadChildren: './paginas/listaproveedor/listaproveedor.module#ListaproveedorPageModule' },

  { path: 'detalle-compra/:idPedido', canActivate: [ClienteAutenticadoGuard], loadChildren: './paginas/detalle-compra/detalle-compra.module#DetalleCompraPageModule' },
  { path: 'listaproductoproveedor/:idprov', loadChildren: './paginas/listaproductoproveedor/listaproductoproveedor.module#ListaproductoproveedorPageModule' },
  // { path: 'cancelacion-pedido/:idPedido', loadChildren: './paginas/cancelacion-pedido/cancelacion-pedido.module#CancelacionPedidoPageModule' },
  // { path: 'propuesta-pedido/:idPedido', loadChildren: './paginas/propuesta-pedido/propuesta-pedido.module#PropuestaPedidoPageModule' },
  // { path: 'detalle-propuesta', loadChildren: './paginas/detalle-propuesta/detalle-propuesta.module#DetallePropuestaPageModule' },
  // {
  //   path: 'estado-pedido/:idPedido',
  //   loadChildren: './paginas/estado-pedido/estado-pedido.module#EstadoPedidoPageModule'
  // },
  { path: 'productodisponible', loadChildren: './paginas/productodisponible/productodisponible.module#ProductodisponiblePageModule' },
  { path: 'validar', loadChildren: './paginas/validar/validar.module#ValidarPageModule' },
  { path: 'contratos', loadChildren: './paginas/admin/contratos/contratos.module#ContratosPageModule' },
  // { path: 'presupuesto', loadChildren: './paginas/presupuesto/presupuesto.module#PresupuestoPageModule' },
  // { path: 'presupuesto-detalle', loadChildren: './paginas/presupuesto-detalle/presupuesto-detalle.module#PresupuestoDetallePageModule' },
  // { path: 'presupuesto-lista', loadChildren: './paginas/presupuesto-lista/presupuesto-lista.module#PresupuestoListaPageModule' },
  // { path: 'inicio-web', loadChildren: './paginas/inicio-web/inicio-web.module#InicioWebPageModule' },


  
  //  { path: 'menu-web', loadChildren: './paginas/menu-web/menu-web.module#MenuWebPageModule' },
  // { path: 'calculadora', loadChildren: './paginas/calculadora/calculadora.module#CalculadoraPageModule' },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 