import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-modal-compra',
  templateUrl: './modal-compra.component.html',
  styleUrls: ['./modal-compra.component.scss'],
})
export class ModalCompraComponent implements OnInit {
  @Input() compra;
  x: any;
  i : any = 0;
  constructor(
    public modalController: ModalController,
    // public nav: NavController,

  ) { }
  nav: NavController;

  ngOnInit() {

  }
  ionViewDidEnter() {
    
    console.log(this.compra);
    var audio = new Audio('../../../assets/audio/check.mp3');
    audio.play();
    this.i = 0 ;
    var _this = this;

    // let x = setInterval(function () {

    //   console.log(_this.i);
    //   if(_this.i==0){
        
        
    //   }
    //   if(_this.i>4){
    //     console.log('es mayor');
    //     clearInterval(x);
      
    //     _this.modalController.dismiss({ res: 0});
        

    //   }
    //   _this.i++;

    // }, 1000);
  }

  continuar(){
    
    this.modalController.dismiss({ res: 0});

  }
}
