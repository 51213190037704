import { Component, Input, OnInit } from '@angular/core';


import * as firebase from 'firebase';
import { LocalserviceService } from "src/app/servicio/localservice.service";
import { ServicioService } from 'src/app/servicio/servicio.service';
import { ModalController, NavController } from '@ionic/angular';

import { WindowService } from '../../servicio/window.service';

@Component({
  selector: 'app-modal-msg',
  templateUrl: './modal-msg.component.html',
  styleUrls: ['./modal-msg.component.scss'],
})
export class ModalMsgComponent implements OnInit {

  @Input () telefono : any;

  windowRef: any;
  phoneNumber : any = {};
  cargando = false;
  verificationCode: string;
  user: any;
  validarTelefono: boolean = false;

  constructor(
    private win: WindowService,
    private ls: LocalserviceService,
    private servicio: ServicioService,
    private nav: NavController,
    public modalController: ModalController,

  ) { }

  ngOnInit() {
    this.validarTelefono = false;
    this.phoneNumber.line = this.telefono;
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')

    this.windowRef.recaptchaVerifier.render()
  }

  sendLoginCode() {

    
    const appVerifier = this.windowRef.recaptchaVerifier;

    const num = `+591${this.phoneNumber.line}`; //this.phoneNumber.e164;

    firebase.auth().signInWithPhoneNumber(num, appVerifier)
            .then(result => {
                this.windowRef.confirmationResult = result;
            })
            .catch( error => {
              console.log(error)
              this.ls.mensajeToast(error);
            } );
            this.validarTelefono = true;
    

  }

  verifyLoginCode() {
    this.cargando= true;

    this.windowRef.confirmationResult
                  .confirm(this.verificationCode.toString())
                  .then( result => {

                    this.ls.mensajeToast('Código verificado. Obteniendo datos de usuario...');

                    console.log('EXITO')
                    this.user = result.user;
                    this.cargando= false;
                    this.modalController.dismiss({ res: 0});

                    //loguear y redirigir
                    // this.servicio.postLoginTelefono(this.phoneNumber.line).subscribe(
                    //   res => {
              
                    //     let validacion = this.ls.verificarResultado(res);
                    //     if (validacion == '0') {
                    //       // this.msjLogin = 'Datos ingresador correctamente.'
              
                    //       console.log(res);
                    //       sessionStorage.setItem("gobox_userApp", JSON.stringify(res['msg']));
                    //       this.ls.mostrarNav = true;
              
                    //       var retorno = sessionStorage.getItem('gobox_ruta_retorno');
                          
                    //       // this.nav.navigateForward('/menu-web/inicio');
                    //       this.modalController.dismiss({ res: 0});
                          
              
                    //     } else if (validacion == '2') {
                    //       this.ls.mensajeToast('No hemos encontrado tu usuario, verifica si ese es el numero '+ this.phoneNumber.line +' fue el que solicitaste registrar');
                    //       // this.msjLogin= 'No hemos encontrado tu usuario, verifica los datos'
                    //     } else if (validacion == '5') {
                    //       console.log(res, "errorCode == 5")
                    //       sessionStorage.setItem("gobox_userApp", JSON.stringify(res['msg']));
                    //       this.nav.navigateForward('/actualizar-clave');
              
                    //     }
                    //   },
                    //   err => {
                    //     console.log(err);
                    //     this.ls.mensajeToast('Error: ' + JSON.stringify(err));
                    //     // this.msjLogin = 'Ocurrio un error, intenta mas tarde.'
              
                    //   }
                    // );


    })
    .catch( error => {
      this.cargando= false;
      this.modalController.dismiss({ res: 1});

      this.ls.mensajeToast('Error: codigo incorrecto ' + JSON.stringify(error));
      console.log(error, "Incorrect code entered?")});
  }
}
