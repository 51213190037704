import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// import { MenuComponent } from './menu/menu.component';
import { PopinfoComponent } from './popinfo/popinfo.component';
import { PopoverMapComponent } from './popover-map/popover-map.component';
import { PopoverMsjTransporteComponent } from './popover-msj-transporte/popover-msj-transporte.component';
import { PopoverMsjProveedorComponent } from './popover-msj-proveedor/popover-msj-proveedor.component';
import { TabWebComponent } from './tab-web/tab-web.component';
// import {HeaderWebComponent} from './header-web/header-web.component';
import { FooterWebComponent } from './footer-web/footer-web.component';
import { PopoverNotificacionesComponent } from './popover-notificaciones/popover-notificaciones.component';
import { PipesModule } from '../pipes/pipes.module';
import { ProductoComponent } from './producto/producto.component';
import { ModalCompraComponent } from './modal-compra/modal-compra.component';
import { ModalMsgComponent } from './modal-msg/modal-msg.component';


@NgModule({
  declarations: [
    // MenuComponent,
    PopinfoComponent,
    PopoverMapComponent,
    PopoverMsjTransporteComponent,
    PopoverMsjProveedorComponent,
    TabWebComponent,
    // HeaderWebComponent,
    FooterWebComponent,
    PopoverNotificacionesComponent,
    ProductoComponent,
    ModalCompraComponent,
    ModalMsgComponent

  ], exports: [
    PopinfoComponent,
    PopoverMapComponent,
    PopoverMsjTransporteComponent,
    PopoverMsjProveedorComponent,
    TabWebComponent,
    // HeaderWebComponent,
    FooterWebComponent,
    PopoverNotificacionesComponent,
    ProductoComponent,
    ModalCompraComponent,
    ModalMsgComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    PipesModule,
    FormsModule
  ]
})
export class ComponentesModule { }
