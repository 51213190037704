import { Component, OnInit, HostListener } from '@angular/core';
import { MenuController, NavController, ToastController, PopoverController } from '@ionic/angular';
import { ServicioService } from 'src/app/servicio/servicio.service';
import { LocalserviceService } from 'src/app/servicio/localservice.service';

@Component({
  selector: 'app-popover-notificaciones',
  templateUrl: './popover-notificaciones.component.html',
  styleUrls: ['./popover-notificaciones.component.scss'],
})
export class PopoverNotificacionesComponent implements OnInit {
  // idCliente: any;
  // token: any;
  notificaciones: any;
  limite: number = 5;
  posicion: number = 0;

  mostrar: number = 0;

  screenHeight: number;
  screenWidth: number;
  esWeb: boolean = true;

  constructor(
    private menuCrtl: MenuController,
    private servicio: ServicioService,
    private nav: NavController,
    private ls: LocalserviceService,
    public toastController: ToastController,
    public popoverController: PopoverController,

  ) {

    this.onResize();

  }
  foto: any = "";
  ngOnInit() {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 650) {
      this.esWeb = false;
    } else {
      this.esWeb = true;
    }
  }
  ionViewWillEnter() {
    //  obtener cliente desde el sessionStorage
    // var a =JSON.parse( sessionStorage.getItem('gobox_userApp'));
    // if(a != null || a!= undefined){
    //   this.idCliente = a['idCliente'];
    //   this.token = a['token']
    //   // console.log(this.idCliente)
    //   // console.log(this.token)
    // } else {
    //   sessionStorage.removeItem('gobox_userApp');
    //   this.nav.navigateForward('/login');
    // }

    this.getNotificaciones();


  }
  getNotificaciones() {
    this.servicio.getNorificacionesCliente(this.ls.getIdCliente(), this.ls.getTokenCliente(), this.limite = 5, this.posicion = 0).subscribe(
      res => {
        console.log(res);
        let validacion = this.ls.verificarResultado(res);
        if (validacion == '0') {
          this.notificaciones = res['msg']
          console.log(this.notificaciones);
        } else if (validacion == '2') {
          // this.toast('No hay datos');

          this.notificaciones = [];
          this.mostrar = 1;

          if (this.ls.getIdCliente() == 0) {

          }
        }

      },
      err => {
        console.log(err);
        this.toast('error:' + err);

      }
    );
  }
  navegar(tipo: number, id: number, calificar: number) {
    // console.log(tipo,'***--',id);
    this.popoverController.dismiss();
    if (tipo == 1) {
      //navegar a nada
    } else if (tipo == 2) {
      //navegar a cosito-propuesta
      this.nav.navigateForward(`/menu-web/respuesta-cosito/historial/${id}`,);

    } else {
      if (tipo == 3 && calificar == 0) {
        // navegar a pedido
        this.nav.navigateForward(`/menu-web/estado-pedido/${id}`,);

      } else {
        this.nav.navigateForward(`/menu-web/calificar/${id}`)

      }
    }
  }

  // eventoRefresher (ev) {
  //   // console.log('Begin async operation');

  //   setTimeout(() => {
  //     // console.log('Async operation has ended');
  //     this.mostrar = 0;
  //     this.getNotificaciones()
  //     ev.target.complete();
  //   }, 2000);
  // }

  async toast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 5000
    });
    toast.present();
  }
  infiniteNotificacion(event) {
    setTimeout(() => {
      this.posicion = this.posicion + 5;

      this.servicio.getNorificacionesCliente(this.ls.getIdCliente(), this.ls.getTokenCliente(), this.limite, this.posicion).subscribe(
        res => {
          console.log('res :>> ', res);
          //  console.log(res,'getProductosTipo');
          let validacion = this.ls.verificarResultado(res);
          if (validacion == '0') {
            //  this.mostrarFiltro = 3;
            let add = res['msg'];
            for (let i = 0; i < add.length; i++) {
              this.notificaciones.push(add[i]);
              //  console.log(this.producto)
            }
            console.log(this.notificaciones)

          } else if (validacion == '2') {
            this.mostrar = 1;
            //  this.toast('No hay datos');
          } else if (validacion == '3') {
            this.toast('Error en el servicio')
          }
        }
      );
      //  event.target.complete();

    }, 500);
  }

  login() {
    this.popoverController.dismiss();
    this.nav.navigateRoot('login');
  }
}
